import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_SALE_ORDERV2 } from '../../connection.data';

@Injectable()
export class SaleOrderService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_SALE_ORDERV2;
    }
}